<template>
  <b-card-text>
    <div class="card w-100 text-center" v-if="loading">
      <div class="card-body">
        <div class="text-center text-info my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </div>
    </div>
    <div class="card w-100 text-center" v-else-if="error">
      <div class="card-body">
        <div class="text-center text-danger my-2">
          <span class="mb-2 d-block">Erro ao carregar dados!</span>
        </div>
      </div>
    </div>
    <div class="card w-100 text-center" v-else-if="store == null">
      <div class="card-body py-5 text-center">
        <h5 class="mb-3">Nenhum item encontrado!</h5>
        <img width="250" src="@/assets/images/not-data.svg" />
      </div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <div>
          <h4 class="card-title">Opções de Parcelamento</h4>
          <hr />
          <div class="row">
            <div class="col-12 mb-4">
              <label>Quantidade máxima de parcelas</label>
              <b-form-select
              v-model="store.installment"
              label="text"
              placeholder="Parcelamento"
              :options="options"
            ></b-form-select>
            </div>
            <div class="col-12 mb-4">
              <label>Quantidade de parcelas sem juros</label>
              <b-form-select
              v-model="store.installment_juros"
              label="text"
              placeholder="Parcelamento sem juros"
              :options="options"
            ></b-form-select>
            </div>
            <div class="col-12 mb-4">
              <label>Juros (%)</label>
              <b-form-input
                v-model="store.juros"
                placeholder="juros (%)"
                for="text"
                v-money="money"
              ></b-form-input>
              <small>% de juros (juros composto)</small>
            </div>
            <div class="col-12 mt-3">
              <b-button variant="success" @click.prevent="salvarDados()">
                <i class="bx bx-save font-size-16 align-middle mr-2"></i>
                Salvar
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-card-text>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      store: null,
      loading: true,
      error: false,
      options:[
        {value: 1, text: "1X"},
        {value: 2, text: "2X"},
        {value: 3, text: "3X"},
        {value: 4, text: "4X"},
        {value: 5, text: "5X"},
        {value: 6, text: "6X"},
        {value: 7, text: "7X"},
        {value: 8, text: "8X"},
        {value: 9, text: "9X"},
        {value: 10, text: "10X"},
        {value: 11, text: "11X"},
        {value: 12, text: "12X"},
      ]
    }
  },
  created() {
    this.findStore()
  },
  methods: {
    ...mapActions('appearance', [
      'ActionAppearanceFind',
      'ActionAppearanceUpdate',
    ]),
    async salvarDados() {
      try {
        await this.ActionAppearanceUpdate(this.store)
        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        })
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
    async findStore() {
      try {
        await this.ActionAppearanceFind().then((res) => {
          this.store = res.data.data
          this.loading = false
          this.error = false
        })
      } catch (e) {
        this.loading = false
        this.error = true
        this.store = null
      }
    },
  },
}
</script>
